<script>
import draggable from "vuedraggable";
import LegoTooltip from "@/components/Lego/LegoTooltip.vue";
import CardInColumn from "@/components/Lego/CardInColumn.vue";
import CreateCard from "@/components/Lego/CreateCard.vue";
import CreatorColumns from "@/components/Lego//CreatorColumns.vue";

export default {
  components: {
    draggable,
    LegoTooltip,
    CardInColumn,
    CreateCard,
    CreatorColumns,
  },
  data() {
    return {
      sizesBoards: [600, 700, 300, 200, 500],
      isSkeleton: true,
    };
  },
  computed: {
    board() {
      return this.$store.state.lego.board;
    },
    columns() {
      console.log(this.board.columns);

      if (this.board.columns) {
        this.isSkeleton = false;
        return this.board.columns;
      } else {
        return [];
      }
    },
    roleLevel() {
      return this.$store.state.lego.currencyRole.level;
    },
  },
  methods: {
    createColumn(column) {
      // Seria bueno agregar un loader congelando la vista hasta que se cree la columna.
      column.LegoBoardId = this.board.id;
      console.log(column);
      this.$store.dispatch("lego/addColumn", column);
    },
    setTitle(ev, column) {
      const title = ev.target.value.trim();
      if (!title) return;
      this.$store.dispatch("lego/setItemInColumn", {
        columnId: column.id,
        obj: { title },
      });
      ev.target.blur();
    },
    setColor(ev, column) {
      const color = ev.target.value.trim();
      if (!color) return;
      this.$store.dispatch("lego/setItemInColumn", {
        columnId: column.id,
        obj: { color },
      });
    },
    onEndColumn(ev) {
      const { oldIndex, newIndex } = ev;
      let newColumns = [...this.columns]; // Clona la lista para evitar mutaciones directas
      const movedItem = newColumns.splice(oldIndex, 1)[0];
      newColumns.splice(newIndex, 0, movedItem);
      this.$store.dispatch("lego/setColumns", newColumns);
    },
    deleteColumn(column) {
      this.$store.dispatch("lego/deleteColumn", column);
    },
    createCard(card, column) {
      card.LegoColumnId = column.id;
      this.$store.dispatch("lego/addCard", card);
    },
    inputCardDraggable(cards, columnId) {
      this.$store.dispatch("lego/moveCard", { cards, columnId });
    },
  },
};
</script>

<template>
  <div class="board">
    <draggable
      class="wrapper-columns"
      :group="{ name: 'columns' }"
      animation="300"
      :columns="columns"
      @end="onEndColumn"
      handle=".drag-handle"
    >
      <div
        :style="{ boxShadow: '0 4px 4px .1px var(--lego-shadow)' }"
        v-show="!isSkeleton || columns.length"
        v-for="column in columns"
        :key="column.id"
        :id="column.id"
      >
        <div
          :style="{ boxShadow: 'inset 0 4px 0 0' + column.color }"
          class="column drag-handle"
        >
          <header class="d-flex justify-content-between align-items-center">
            <vs-button
              v-b-tooltip
              title="Tarjetas totales"
              flat
              warn
              style="
                width: 30px;
                height: 30px;
                border-radius: 1rem !important;
                cursor: default !important;
              "
            >
              {{ column.cards.length }}
            </vs-button>
            <input
              class="column-title"
              type="text"
              :value="column.title"
              @keydown.enter="setTitle($event, column)"
              @blur="setTitle($event, column)"
            />
            <!-- * control column -->
            <LegoTooltip position="bottom">
              <!-- TODO: darle focus redirectorio -->
              <!-- <span>Editar nombre</span> -->
              <div class="wrapper-color">
                <label :for="column.id + 'color'">
                  <span>Color</span>
                </label>
                <input
                  :id="column.id + 'color'"
                  class="color"
                  type="color"
                  :value="column.color"
                  @blur="setColor($event, column)"
                />
              </div>
              <span class="text-lego-danger" @click="deleteColumn(column)"
                >Eliminar columna</span
              >
            </LegoTooltip>
          </header>

          <draggable
            class="wrapper-tasks"
            ghost-class="bg-blue-200"
            @input="inputCardDraggable($event, column.id)"
            :value="column.cards"
            :group="{ name: 'cards' }"
            animation="300"
          >
            <CreateCard
              @createCard="createCard($event, column)"
              v-if="roleLevel < 3"
            />
            <CardInColumn
              v-for="card in column.cards"
              :key="card.id"
              :card="card"
              :id="card.id"
            />
          </draggable>
        </div>
      </div>
      <b-skeleton
        v-show="isSkeleton"
        v-for="column in 5"
        :key="column"
        width="300px"
        :height="`${sizesBoards[column - 1]}px`"
      ></b-skeleton>
    </draggable>

    <CreatorColumns @createColumn="createColumn" v-if="roleLevel < 3" />
  </div>
</template>

<style scoped>
.board {
  flex: 1;
  padding: 1rem;
  display: flex;
  overflow: auto;
  /* gap: 1rem; */
}

.board {
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  gap: 1rem;
}

.wrapper-columns {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.column {
  position: relative;
  padding: 1rem;
  width: 300px;
  border-radius: 3px;
  cursor: move;
  /* box-shadow: inset 0 4px 0 0 var(--lego-dbg); */
  background-color: var(--lego-bg);
  .dark & {
    background-color: var(--lego-oscuro);
  }
}

.column-title {
  background-color: transparent;
  letter-spacing: 0.25rem;
  border: 0;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  color: white;
  padding: 0.5rem;
  border-radius: 3px;
}

.column-title:focus {
  background-color: var(--lego-light-hover);
  cursor: text;
}

.wrapper-tasks {
  /* border-top: 0.25rem solid; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.wrapper-color {
  padding: 0;
  position: relative;
}

.wrapper-color label {
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.color {
  position: absolute;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  width: 0px;
  height: 0px;
  transform: translateY(-2px);
}
</style>
