<script>
export default {
  data() {
    return {
      title: "",
      focused: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.userLoggedIn;
    },
  },
  methods: {
    createCard() {
      const title = this.title.trim();
      if (title === "") return;

      const card = {
        title,
        createdBy: this.user.id,
      };

      this.$emit("createCard", card);
      this.title = "";
    },
    cancelTask(ev) {
      this.title = "";
      this.focused = false;
      ev.target.blur();
    },
  },
};
</script>
<template>
  <div>
    <hr />
    <textarea
      class="task"
      v-model="title"
      @keydown.tab="createCard"
      @keyup.enter="createCard"
      @keyup.esc="cancelTask"
      @focus="focused = true"
      @blur="focused = false"
      :placeholder="
        !focused
          ? '+ Agregar tarjeta'
          : 'Ingrese un título para esta tarjeta...'
      "
    ></textarea>
    <div class="controls" :class="{ focus: focused }">
      <vs-button class="mr-1" @click="createCard">Crear</vs-button>
      <vs-button danger class="" @click="cancelTask">Cancelar</vs-button>
    </div>
  </div>
</template>

<style scoped>
.task {
  width: 100%;
  border-radius: 5px;
  background-color: transparent;
  transition: 0.5s;
  border: none;
  /* outline: 1px solid var(--primary); */
  resize: none;
  padding: 0.5rem;
  height: 2rem;
}
.dark .task {
  color: white;
}

.task:not(:focus)::placeholder {
  color: var(--primary) !important;
  /* text-align: center; */
  font-weight: bold;
}

.task:focus {
  background-color: var(--lego-light-hover);
  outline: none;
  height: 4rem;
}

.task::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.controls {
  visibility: hidden;
  display: flex;
  margin-top: 0.5rem;
  transition: 0.3s;
  height: 0;
}
.controls.focus {
  visibility: visible;
  height: 40px;
}
</style>
