<script>
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue";

export default {
  components: {
    Tooltip2Vue,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  computed: {
    auditoria() {
      return this.card.auditoria;
    },
    options() {
      return this.$store.state.lego.auditoriaOptions;
    },
  },
  methods: {
    input(auditoria, close) {
      if (auditoria === null || auditoria === undefined) return;
      const card = Object.assign({}, this.card, {
        legoAuditoriaId: auditoria.id == null ? null : auditoria.id,
        auditoria: auditoria.id == null ? null : auditoria,
      });
      console.log(card);
      this.$store.dispatch("lego/setAsyncItemInCard", card);
      close();
    },
  },
};
</script>

<template>
  <Tooltip2Vue :position="position">
    <div class="btn-shadow">
      <span><i class="fa-regular fa-bookmark"></i></span>
      <span>{{ auditoria ? auditoria.name : "Auditoria" }}</span>
    </div>
    <template #tooltip="{ close }">
      <div class="tp-container">
        <div class="mb-2">
          <span>Auditoria</span>
        </div>
        <v-select
          class="lego"
          :options="options"
          @input="input($event, close)"
          :value="auditoria"
          :get-option-label="(option) => option.name"
        ></v-select>
      </div>
    </template>
  </Tooltip2Vue>
</template>

<style scoped>
.btn-shadow {
  padding: 0.5rem;
  border: solid 1px #cacaca;
  /* background-color: transparent; */
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.tp-container {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 8px;
  cursor: auto;
  width: 300px;
  text-align: start;
  border: solid 1px rgba(0, 0, 0, 0.25);
  .dark & {
    border: solid 1px rgba(255, 255, 255, 0.25);
  }
}
</style>
